import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { logout as deconnexion } from 'database/Api';

export const UseAutoLogout = () => {

//   window.addEventListener('beforeunload', (event) => {
//     // Supprimez le token du localStorage
//     localStorage.removeItem('admin-token');
//   });

  useEffect(() => {
    let timeoutId;
    let timerInterval;

    const logout = () => {
      const isAuth = localStorage.getItem('admin-token') !== null;
      if (!isAuth) return; // Ne pas déconnecter si l'utilisateur n'est pas authentifié

      Swal.fire({
        title: 'Déconnexion automatique',
        html: 'Vous serez déconnecté dans <strong></strong> secondes.<br/><br/>' +
              '<button id="cancel" class="btn btn-primary my-button">' +
                'Annuler la déconnexion' +
              '</button>',
        timer: 5 * 1000, // Déconnexion après 15 minutes d'inactivité
        didOpen: () => {
          Swal.showLoading();

          const content = Swal.getHtmlContainer();
          const $ = content.querySelector.bind(content);

          const cancel = $('#cancel');

          cancel.addEventListener('click', () => {
            Swal.stopTimer();
            Swal.close();
          });

          timerInterval = setInterval(() => {
            Swal.getHtmlContainer().querySelector('strong')
              .textContent = (Swal.getTimerLeft() / 1000)
                .toFixed(0);
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
            console.log('ici')
          deconnexion();
          localStorage.removeItem('admin-token');
          window.location.replace('/');
        }
      });
    };

    const setupTimeout = () => {
      timeoutId = setTimeout(logout, 15 * 60 * 1000); // Affiche le SweetAlert après 5 secondes d'inactivité
    };

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      setupTimeout();
    };

    setupTimeout();
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('mousedown', resetTimeout);
    window.addEventListener('keypress', resetTimeout);
    window.addEventListener('touchmove', resetTimeout);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('mousedown', resetTimeout);
      window.removeEventListener('keypress', resetTimeout);
      window.removeEventListener('touchmove', resetTimeout);
    };
  }, []);
};
