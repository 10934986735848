import { faker } from '@faker-js/faker'
import _ from 'lodash'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import axios from 'axios'

/* urls */
export const host =
  ' https://bo.madio-vpc.mg'
  // 'http://localhost:8000'
  // 'http://192.168.1.125:8000'
  // 'http://localhost/project/Back-Office/public'
// auth
const loginUrl = `${host}/loginAdmin`
const logoutUrl = `${host}/logoutAdmin`

//admin
const adminUrl = `${host}/admin`

//Clients
const clientsUrl = `${host}/clients`
const checkClientsUrl = `${host}/checkClients`
const clientUrl = (id) => `${clientsUrl}/${id}`

//Fournisseurs
const fournisseursUrl = `${host}/fournisseurs`
const fournisseurUrl = (id) => `${fournisseursUrl}/${id}`

//Excel
const excelsUrl = `${host}/excels`
const excelUpdload = `${host}/uploadExcel`
const excelUrl = (filename) => `${excelsUrl}/${filename}`

//Proforma
const proformaUrl = `${host}/proformas`

//Devis
const devisUrl = `${host}/devis`
//Unite
const uniteUrl = `${host}/unite`
const unitesUrl = (id) => `${uniteUrl}/${id}`
//Avancements
const avancementsUrl = `${host}/avancements`
const avancementUrl = (id) => `${avancementsUrl}/${id}`

//Fonctions
const fonctionUrl = `${host}/fonctions`
const fonctionsUrl = (id) => `${fonctionUrl}/${id}`

//Employes 
const employeUrl = `${host}/employes`
const employesUrl = (id) => `${employeUrl}/${id}`

//Type depenses
const typeDepenseUrl = `${host}/typeDepenses`
const typeDepensesUrl = (id) => `${typeDepenseUrl}/${id}`

//Categorie depenses
const categorieDepenseUrl = `${host}/categorieDepenses`
const categorieDepensesUrl = (id) => `${categorieDepenseUrl}/${id}`

//Achats
const achatUrl = `${host}/achats`
const achatsUrl = (id) => `${achatUrl}/${id}`

//Demande stock
const demandeStockChantierUrl = `${host}/demandeStockChantier`
const demandesStockChantierUrl = (id) => `${demandeStockChantierUrl}/${id}`

//Entrer stock
const entrerStockChantierUrl = `${host}/entrerStockChantier`
const entrerStockChantierUrls = (id) => `${entrerStockChantierUrl}/${id}`

//Sortie stock
const sortieStockChantierUrl = `${host}/sortieStockChantier`
const sortieStockChantierUrls = (id) => `${sortieStockChantierUrl}/${id}`

//Stock Madio
const stockMadioUrl = `${host}/stockMadio`
const stockMadioUrls = (id) => `${stockMadioUrl}/${id}`

//Articles
const articleUrl = `${host}/articles`
const articlesUrl = (id) => `${articleUrl}/${id}`

//Depenses chantier
const depenseChantierUrl = `${host}/depensesChantier`
const depensesChantierUrl = (id) => `${depenseChantierUrl}/${id}`

//Acompte chantier
const acompteChantierUrl = `${host}/acompteChantier`
const acompteChantierUrls = (id) => `${acompteChantierUrl}/${id}`

//Madio
const madioUrl = `${host}/madio`
const madioUrls = (id) => `${madioUrl}/${id}`

/* api calls */
//prendre le nouveau token 
axios.interceptors.response.use(
  response => {
      // Vérifiez si la requête a un paramètre personnalisé `extractToken` défini sur `true`
      if (response.config.extractToken) {
          // Vérifiez si un nouveau token est présent dans l'en-tête `Authorization` de la réponse
          const authHeader = response.headers['authorization'];
          if (authHeader && authHeader.startsWith('Bearer ')) {
              // Extrait le nouveau token de l'en-tête `Authorization`
              const newToken = authHeader.substring(7);
              // Stockez le nouveau token dans le stockage local
              localStorage.setItem('admin-token', newToken);
          }
      }
      return response;
  },
  error => {
      if (error.response.status === 401) {
          // Le jeton est invalide, déconnecter l'utilisateur
          if(localStorage.getItem('admin-token')){
            logout();
            localStorage.removeItem('admin-token')
            window.location.replace('/')
          }  
      }
      return Promise.reject(error);
  }
);

// Generic
export const getCall = (url, auth = false, extractToken = false) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: `Bearer ${localStorage.getItem('admin-token')}` } }
  }
  config.extractToken = extractToken;
  return axios
    .get(url, config)
    .then((res) => (res.status >= 200 && res.status < 300 ? res : Promise.reject(res)))
    .then((res) => res.data.data)
}

export const postCall = (url, data, auth = false, extractToken = false) => {
  let config = {};
  if (auth) {
    config.headers = { Authorization: 'Bearer ' + localStorage.getItem('admin-token') };
  }
  // Ajoutez le paramètre personnalisé `extractToken` à la configuration de la requête
  config.extractToken = extractToken;
  return axios
    .post(url, data, config)
    .then((res) => (res.status >= 200 && res.status < 300 ? res : Promise.reject(res)))
    .then((res) => res.data.data);
};

export const putCall = (url, data, auth = false,  extractToken = false) => {
  let config = {}
  if (auth) {
    config = { headers: { Authorization: 'Bearer ' + localStorage.getItem('admin-token') } }
  }
  config.extractToken = extractToken;
  return axios
    .put(url, data, config)
    .then((res) => (res.status >= 200 && res.status < 300 ? res : Promise.reject(res)))
    .then((res) => res.data.data)
}

export const deleteCall = (url, data, auth = false,  extractToken = false) => {
  let config = {}
  if (auth) {
    config.headers = { Authorization: `Bearer ${localStorage.getItem('admin-token')}` }
  }
  config.extractToken = extractToken;
  
  // Ajoutez vos données à l'objet de configuration en tant que params
  config.params = data;
  
  return axios
    .delete(url, config)
    .then((res) => (res.status >= 200 && res.status < 300 ? res : Promise.reject(res)))
    .then((res) => res.data.data)
}


//refresh-token
export const refreshToken = () => {
  return getCall(`${host}/refresh-token`, {},true,true)
}

// Authentication
export const login = (user) => {
  return postCall(loginUrl, user)
}
export const logout = () => {
  return getCall(logoutUrl, true)
}

//Admin
export function updateConnectionAdmin(data) {
  return postCall(adminUrl, data,true,true)
}

//Clients
// export const addClients = (data) => {
//   return postCall(clientsUrl, data,true,true)
// }
// export const checkClient = (data) => {
//   return postCall(checkClientsUrl, data,true,true)
// }
export function getClients()  {
  return getCall(clientsUrl, {},true,true)
}
export function getClient(id) {
  return getCall(clientUrl(id), {},true,true)
}
// export function updateClient(id, data) {
//   return putCall(clientUrl(id), data,true,true)
// }
export function importClient(data) {
  return postCall(`${host}/clientsImport`, data,true,true)
}

//Fournisseurs
export function getFournisseurs()  {
  return getCall(fournisseursUrl, {},true,true)
}
export function getFournisseur(id)  {
  return getCall(fournisseurUrl(id), {},true,true)
}
export function importFournisseur(data) {
  return postCall(`${host}/fournisseursImport`, data,true,true)
}
//Excel
export const uploadExcel = (data) => {
  return postCall(excelUpdload, data,true,true)
}
export function getExcels() {
  return getCall(excelsUrl, {},true,true)
}

export const getExcel = (filename) => {
  return getCall(excelUrl(filename), {},true,true)
}

//proforma
export const importProformas = (data) => {
  return postCall(`${host}/proformasImport`, data,true,true)
}
export function getProformasWithClientInfo() {
  return getCall(`${host}/getProformasWithClientInfo`, {},true,true)
}
export function getProformaWithClientById(id) {
  return getCall(`${host}/getProformaWithClientById/${id}`, {},true,true)
}
export function proformaUpdateLocation(data) {
  return putCall(`${host}/proformasLocation`, data,true,true)
}
export function getProformaLocation(id) {
  return getCall(`${host}/proformasLocation/${id}`, {},true,true)
}
export function updateProformaEmploye(data,id) {
  return putCall(`${proformaUrl}/${id}/employes`, data,true,true)
}
export function updateProformaDate(data,id) {
  return putCall(`${proformaUrl}/${id}/dates`, data,true,true)
}
//devis
export const importDevis = (data) => {
  return postCall(`${host}/devisImport`, data,true,true)
}
export function getDevisWithClientInfo() {
  return getCall(`${host}/getDevisWithClientInfo`, {},true,true)
}
export function getDevisWithClientById(id) {
  return getCall(`${host}/getDevisWithClientById/${id}`, {},true,true)
}
export function devisUpdateLocation(data) {
  return putCall(`${host}/devisLocation`, data,true,true)
}
export function getDevisLocation(id) {
  return getCall(`${host}/devisLocation/${id}`, {},true,true)
}
export function updateDevisEmploye(data,id) {
  return putCall(`${devisUrl}/${id}/employes`, data,true,true)
}
export function updateDeviDate(data,id) {
  return putCall(`${devisUrl}/${id}/dates`, data,true,true)
}
//FI
export const importFi = (data) => {
  return postCall(`${host}/fiImport`, data,true,true)
}
export function getFicheInterventionWithClientInfo() {
  return getCall(`${host}/getFicheInterventionWithClientInfo`, {},true,true)
}
//FA
export const importFa = (data) => {
  return postCall(`${host}/faImport`, data,true,true)
}
export function getFacturesAcompteWithClientInfo() {
  return getCall(`${host}/getFacturesAcompteWithClientInfo`, {},true,true)
}
//Factures
export const importFacture = (data) => {
  return postCall(`${host}/factureImport`, data,true,true)
}
export function getFacturesWithClientInfo() {
  return getCall(`${host}/getFacturesWithClientInfo`, {},true,true)
}
//Unite
export function getUnite() {
  return getCall(uniteUrl, {},true,true)
}
export function addUnite(data) {
  return postCall(uniteUrl,data,true,true)
}
export function updateUnite(data,id) {
  return putCall(unitesUrl(id),data,true,true)
}

export function deleteUnite(id) {
  return deleteCall(unitesUrl(id),{},true,true)
}


//Fonctions
export function getFonctions() {
  return getCall(fonctionUrl, {},true,true)
}
export function addFonctions(data) {
  return postCall(fonctionUrl,data,true,true)
}
export function updateFonctions(data,id) {
  return putCall(fonctionsUrl(id),data,true,true)
}

export function deleteFonctions(id) {
  return deleteCall(fonctionsUrl(id),{},true,true)
}
//Avancements
export const addAvancements = (data) => {
  return postCall(avancementsUrl, data,true,true)
}
export function updateAvancements(data) {
  return putCall(avancementsUrl, data,true,true)
}
export function getAvancementsDevis(id) {
  return getCall(`${host}/avancementsDevis/${id}`, {},true,true)
}
export function getAvancementsProforma(id) {
  return getCall(`${host}/avancementsProformas/${id}`, {},true,true)
}

export function deleteAvancements(id) {
  return deleteCall(avancementUrl(id), {},true,true)
}

//image
export function getUploadImage(data) {
  return postCall(`${host}/getUploadImage`, data,true,true)
}
export function deleteImage(data) {
  return postCall(`${host}/deleteImage`, data,true,true)
}

//employes
export const checkEmploye = (data) => {
  return postCall(`${host}/checkEmployes`, data,true,true)
}
export const getNextMatricule = (data) => {
  return postCall(`${host}/getNextMatricule`, data,true,true)
}
export const addEmployes = (data) => {
  return postCall(employeUrl, data,true,true)
}

export function getEmployes (actif){
  return getCall(`${employeUrl}/actif/${actif}`, {}, true, true)
}
export function getEmploye (id){
  return getCall(employesUrl(id), {},true,true)
}
export function updateEmploye(data,id) {
  return putCall(employesUrl(id),data,true,true)
}
export function employeToggleActif (id){
  return getCall(`${employeUrl}/${id}/toggleActif`, {}, true, true)
}

//Type depenses
export function getTypeDepenses() {
  return getCall(typeDepenseUrl, {},true,true)
}
export function addTypeDepenses(data) {
  return postCall(typeDepenseUrl,data,true,true)
}
export function updateTypeDepenses(data,id) {
  return putCall(typeDepensesUrl(id),data,true,true)
}

export function deleteTypeDepenses(id) {
  return deleteCall(typeDepensesUrl(id),{},true,true)
}

//Categorie depenses
export function getCategorieDepenses() {
  return getCall(categorieDepenseUrl, {},true,true)
}
export const getCategorieDepensesByTypeDepense = (type_depense_id) =>{
  return getCall(`${categorieDepenseUrl}/${type_depense_id}`, {},true,true)
}
export function addCategorieDepenses(data) {
  return postCall(categorieDepenseUrl,data,true,true)
}
export function updateCategorieDepenses(data,id) {
  return putCall(categorieDepensesUrl(id),data,true,true)
}

export function deleteCategorieDepenses(id) {
  return deleteCall(categorieDepensesUrl(id),{},true,true)
}

//Achats
export function getAchats() {
  return getCall(achatUrl, {},true,true)
}
export function addAchats(data) {
  return postCall(achatUrl,data,true,true)
}
export function updateAchats(data,id) {
  return putCall(achatsUrl(id),data,true,true)
}

export function deleteAchats(id) {
  return deleteCall(achatsUrl(id),{},true,true)
}
export function getLastUnitPrice(categorie_depense_id,unite_id) {
  return getCall(`${achatUrl}/${categorie_depense_id}/${unite_id}/lastUnitPrice`, {},true,true)
}

export function getAchat(id) {
  return getCall(achatsUrl(id), {},true,true)
}


//Demande stock
export function getDemandesStockChantier() {
  return getCall(demandeStockChantierUrl, {},true,true)
}
export function addDemandeStockChantier(data) {
  return postCall(demandeStockChantierUrl,data,true,true)
}

export function updateDemandeStockChantier(data,id) {
  return putCall(demandesStockChantierUrl(id),data,true,true)
}

export function deleteDemandeStockChantier(id) {
  return deleteCall(demandesStockChantierUrl(id),{},true,true)
}

export function getDemandeStockChantier(id,type) {
  return getCall(`${demandeStockChantierUrl}/${id}/${type}`, {},true,true)
}

export function getTotalDemandeStockChantier(id,type) {
  return getCall(`${demandeStockChantierUrl}/${id}/${type}/totalDemande`, {},true,true)
}

//Entrer stock
export function getEntrerStockChantiers() {
  return getCall(entrerStockChantierUrl, {},true,true)
}
export function addEntrerStockChantier(data) {
  return postCall(entrerStockChantierUrl,data,true,true)
}

export function updateEntrerStockChantier(data,id) {
  return putCall(entrerStockChantierUrls(id),data,true,true)
}

export function deleteEntrerStockChantier(id) {
  return deleteCall(entrerStockChantierUrls(id),{},true,true)
}

export function getEntrerStockChantier(id,type) {
  return getCall(`${entrerStockChantierUrl}/${id}/${type}`, {},true,true)
}



//Sortie stock
export function getSortieStockChantiers() {
  return getCall(sortieStockChantierUrl, {},true,true)
}
export function addSortieStockChantier(data) {
  return postCall(sortieStockChantierUrl,data,true,true)
}

export function updateSortieStockChantier(data,id) {
  return putCall(sortieStockChantierUrls(id),data,true,true)
}

export function deleteSortieStockChantier(id) {
  return deleteCall(sortieStockChantierUrls(id),{},true,true)
}

export function getSortieStockChantier(id,type) {
  return getCall(`${sortieStockChantierUrl}/${id}/${type}`, {},true,true)
}

//Stock madio
export function addOrUpdateStockMadio(data) {
  return postCall(stockMadioUrl, data,true,true)
}

export function getStockMadio() {
  return getCall(stockMadioUrl, {},true,true)
}

export function getTotalStockChantier(id,type) {
  return getCall(`${stockMadioUrl}/${id}/${type}/total`, {},true,true)
}

export function deleteStockMadio(categorie_depense_id,unite_id) {
  return deleteCall(`${stockMadioUrl}/${categorie_depense_id}/${unite_id}`, {},true,true)
}

//Articles
export function addOrUpdateArticles(data) {
  return postCall(articleUrl, data,true,true)
}

export function getArticles() {
  return getCall(articleUrl, {},true,true)
}

export function deleteArticles(id) {
  return deleteCall(articlesUrl(id), {},true,true)
}

export function getArticle(categorie_depense_id,unite_id,fournisseur_id) {
  return getCall(`${articleUrl}/${categorie_depense_id}/${unite_id}/${fournisseur_id}`, {},true,true)
}

//Depenses chantier
export function addDepensesChantier(data) {
  return postCall(depenseChantierUrl, data,true,true)
}

export function getDepensesChantier() {
  return getCall(depenseChantierUrl, {},true,true)
}

export function updateDepensesChantier(data,id) {
  return putCall(depensesChantierUrl(id),data,true,true)
}

export function deleteDepensesChantier(id) {
  return deleteCall(depensesChantierUrl(id), {},true,true)
}

export function getDepenseChantier(id,type) {
  return getCall(`${depenseChantierUrl}/${id}/${type}`, {},true,true)
}

//Acompte chantier
export function addAcompteChantier(data) {
  return postCall(acompteChantierUrl, data,true,true)
}

export function getAcompteChantier(type,type_id) {
  return getCall(`${acompteChantierUrl}/${type}/${type_id}`, {},true,true)
}

export function updateAcompteChantier(data,id) {
  return putCall(acompteChantierUrls(id),data,true,true)
}

export function deleteAcompteChantier(id) {
  return deleteCall(acompteChantierUrls(id), {},true,true)
}

//Madio
export function getStatistiqueChantier(id,type) {
  return getCall(`${madioUrl}/${id}/${type}/chantier`, {},true,true)
}

export function getTopClientsByChiffreAffaire(year) {
  return getCall(`${madioUrl}/${year}/clients/chiffreAffaire`, {},true,true)
}

export function getTopClientsByServiceDemande(year) {
  return getCall(`${madioUrl}/${year}/clients/serviceDemande`, {},true,true)
}

export function getTopFournisseursByAchats(year) {
  return getCall(`${madioUrl}/${year}/fournisseurs/achats`, {},true,true)
}

export function getTopFournisseursByNombreAchats(year) {
  return getCall(`${madioUrl}/${year}/fournisseurs/nombreAchats`, {},true,true)
}

export function getStatistiqueMadio(year) {
  return getCall(madioUrls(year), {},true,true)
}