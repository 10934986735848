import React, { createContext, useEffect } from 'react';
import { refreshToken } from '../../database/Api';

export const IntervalContext = createContext();

export const IntervalProvider = ({ children }) => {
  const [token, setToken] = React.useState(localStorage.getItem('admin-token'));

  // Mettre à jour le token chaque fois qu'il change dans le localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem('admin-token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Rafraîchir le token toutes les 10 secondes si l'utilisateur est connecté
  useEffect(() => {
    if (token !== null) {
      const intervalId = setInterval(() => {
        refreshToken();
      }, 60 * 60 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [token]);

  return <IntervalContext.Provider value={null}>{children}</IntervalContext.Provider>;
};
